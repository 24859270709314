<template>
  <div>
    {{ categorie }}
  </div>
</template>
  
  <script>
  import { mapGetters } from 'vuex';
  export default {
      props: {object: {type: Object, required: true}},
      computed:{
          ...mapGetters({
              categories: 'sanitaire/categorieAnalyses'
          }),
          categorie(){
              if(this.object !== null) {
                let c = this.categories.find(item => item.uid === this.object.categorie)
                if(c) return c.libelle
                return '-'
              }
              return '-'
          }
      }
  }
  </script>
  
  <style>
  
  </style>